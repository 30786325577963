<template>
  <div class="collection-item-theme collection-theme-9" @click="handleClick">
    <div class="collection-item-content">
      <div class="content-tags-row">
        <div class="ctag">
          Recommended
        </div>
        <div class="new-time">
          {{ $global.timeAgo($global.dateStringToTimestamp(item.published)) }}
        </div>
      </div>
      <div class="title">
        {{ item.title }}
      </div>
      <div class="news-info">
        <div class="author-row">
          <div v-if="item.avatar" class="author-avatar" />
          <div class="author-name">
            {{ item.channel }}
          </div>
        </div>
      </div>
    </div>
    <div class="collection-item-sources">
      <img v-lazy="item.headImg?.[0]" alt="">
    </div>
  </div>
</template>

<script>
import '@/css/module/collection/theme_9.scss';

export default {
  props: {
    item: {
      require: true,
      type: Object,
      default () {
        return {}
      }
    },
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
